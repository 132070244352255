body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',"Century",
    monospace;
}

.notify-badge{
  position: absolute;
  bottom: -10px;
  right: -10px;
  background:red;
  text-align: center;
  border-radius: 200px;
  color:white;
  padding:0px 6px;
  font-size:12px;
  font-weight: bold;
}

.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  aspect-ratio: 3/1;
  overflow: visible;
  background-position: center center;
  background-repeat: no-repeat;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  background-color: #F8F8F8;
}

.footer-contact {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.footer-bottom-widgets-menu {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
  flex-basis: 0%;
  flex-grow: 1;
  max-width: 100%;
}

.divider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  background:linear-gradient(to left, #ccc 77%, #ED1C24 23%) bottom;
  background-repeat: no-repeat;
  background-size:100% 1px;
}

.font10 {
  font-size: 10px;
}

.font11 {
  font-size: 11px;
}

.font12 {
  font-size: 12px;
}

.font14 {
  font-size: 14px;
}

.font16 {
  font-size: 16px;
}

.font17 {
  font-size: 17px;
}

.font18 {
  font-size: 18px;
}

.font20 {
  font-size: 20px;
}

.font22 {
  font-size: 22px;
}

.font24 {
  font-size: 24px;
}

.font26 {
  font-size: 26px;
}

.font28 {
  font-size: 28px;
}

.font30 {
  font-size: 30px;
}

.font40 {
  font-size: 40px;
}

.bold {
  font-weight: bold;
}

.white {
  color: white;
}

.black {
  color: white;
}

.form-field {
  display: block;
    width: 100%;
    padding: 0.594rem 1rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.714;
    color: #333e48;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ddd;
    appearance: none;
    border-radius: 1.375rem;
    box-shadow: 0;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.button {
    font-size: .875rem;
    border-radius: 1.571em;
    padding: 1.036em 2.134em;
    border-width: 0;
    display: inline-block;
    color: #333e48;
    background-color: #efecec;
    border-color: #efecec;
    transition: all .2s ease-in-out;
    font-weight: 700;
    color: #ffffff;
    background-color: #ed1c24;
    border-color: #ed1c24;
}

.button:hover {
  font-size: .875rem;
  border-radius: 1.571em;
  padding: 1.036em 2.134em;
  border-width: 0;
  display: inline-block;
  color: #333e48;
  background-color: #efecec;
  border-color: #efecec;
  transition: all .2s ease-in-out;
  font-weight: 700;
  color: #ffffff;
  background-color: black;
  border-color: black;
}

.orders-table {
    display: table;
    /* border-collapse: separate; */
    box-sizing: border-box;
    text-indent: initial;
    /* border-spacing: 2px; */
    /* border-color: gray; */
    text-align: start;
    font-size: 14px;
}

.orders-table tr th{
  padding: 0.75rem;
  line-height: 1.5;
  vertical-align: top;
  border-top: 1px solid #eceeef;
  font-size: 14px;
}

.order-item {
  padding: 0.75rem;
  line-height: 1.5;
  vertical-align: top;
  border-top: 1px solid #eceeef;
  font-size: 14px;
  text-decoration: none;
}

.order-view-button {
    border-radius: 1.571em;
    background-color: #efecec;
    border-color: #efecec;
    width: 65px;
    height: 32px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 12px;
    color: #333e48;
    text-decoration: none;
}

.order-view-button:hover {
  border-radius: 1.571em;
  background-color: black;
  border-color: black;
  width: 65px;
  height: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 12px;
  color: white;
  text-decoration: none;
}

.pointer {
  cursor: pointer;
}

.loader {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid red; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

.page-item {
  padding: 0;
  width: 35px;
  height: 35px;
  line-height: 34px;
  text-align: center;
  vertical-align: middle;
  color: #7e7e7e;
  display: block;
  border: 1px solid #e3e3e3;
  border-radius: 50%;
  cursor: pointer;
}

.page-item:hover {
  background-color: #E3E3E3;
}

.page-item-selected {
  padding: 0;
  width: 35px;
  height: 35px;
  line-height: 34px;
  text-align: center;
  vertical-align: middle;
  color: white;
  display: block;
  border: 1px solid #e3e3e3;
  border-radius: 50%;
  font-weight: bold;
  background-color: #ed1c24;
  cursor: pointer;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.header-branch {
  color: #4E5A5A;
  font-size: 13;
  height: 36;
  display: flex;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
}

.header-search-option:hover {
  background-color: #DA1A21;
}

/* The sticky class is added to the header with JS when it reaches its scroll position */
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  box-shadow: 0 10px 10px -10px #55555583;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
  padding-top: 102px;
}

.sidenav-ltr-opened {
    height: 100%;
    width: 250px;
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
    -webkit-transition: width 0.5s;
    -moz-transition: width 0.5s;
    -o-transition: width 0.5s;
    transition: width 0.5s;
    padding: 0;
    background-color: #333333;
    scrollbar-width: none;
}

.sidenav-ltr-closed {
  height: 100%;
  width: 0;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: width 0.5s;
  padding: 0;
  background-color: #333333;
  scrollbar-width: none;
}

.sidenav-rtl-opened {
  height: 100%;
  width: 250px;
  position: fixed;
  top: 0;
  right: 0;
  overflow-x: hidden;
  -webkit-transition: width 0.5s;
  -moz-transition: width 0.5s;
  -o-transition: width 0.5s;
  transition: width 0.5s;
  padding: 0;
  background-color: #333333;
  scrollbar-width: none;
}

.sidenav-rtl-closed {
  height: 100%;
  width: 0;
  position: fixed;
  top: 0;
  right: 0;
  overflow-x: hidden;
  transition: width 0.5s;
  padding: 0;
  background-color: #333333;
  scrollbar-width: none;
}

.sidenav-rtl-opened::-webkit-scrollbar { 
  display: none;
}

.sidenav-rtl-closed::-webkit-scrollbar { 
  display: none; 
}

.sidenav-ltr-opened::-webkit-scrollbar { 
  display: none;
}

.sidenav-ltr-closed::-webkit-scrollbar { 
  display: none; 
}

.sidenav-item {
  cursor: pointer;
  height: 43px;
  background-color: #333333;
  border-bottom: 1px solid #262626;
  color: #c4c4c4;
  overflow: hidden;
  max-height: 43px;
  max-lines: 1;
  white-space: nowrap;
}

.sidenav-item:hover {
  background-color: #B3B3B3;
  color: #FFFFFF;
}

.sidenav-item-nested {
  cursor: pointer;
  height: 43px;
  background-color: black;
  border-bottom: 1px solid #262626;
  color: #c4c4c4;
  overflow: hidden;
  max-height: 43px;
  max-lines: 1;
  white-space: nowrap;
}

.sidenav-item-nested:hover {
  background-color: #B3B3B3;
  color: #000;
}

@media (min-width: 800px) {
  .base-ltr-opened {
    transition: margin-left .5s;
    margin-left: 250px;
  }
  
  .base-ltr-closed {
    transition: margin-left .5s;
    margin-left: 0px;
  }
  
  .base-rtl-opened {
    transition: margin-right .5s;
    margin-right: 250px;
  }
  
  .base-rtl-closed {
    transition: margin-right .5s;
    margin-right: 0px;
  }
}

.cover-ltr-opened {
  z-index: 1000;
  background-color: #0000003b;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  visibility: visible;
  transition: margin-left .5s;
  overflow-y:scroll;
  overflow-x:hidden;
  margin-left: 250px;
}

.cover-ltr-closed {
  z-index: 1000;
  background-color: #0000003b;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  visibility: hidden;
  transition: margin-left .5s;
  overflow-y:scroll;
  overflow-x:hidden;
  margin-left: 0px;
}

.cover-rtl-opened {
  z-index: 1000;
  background-color: #0000003b;
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  visibility: visible;
  transition: margin-right .5s;
  overflow-y:scroll;
  overflow-x:hidden;
  margin-right: 250px;
}

.cover-rtl-closed {
  z-index: 1000;
  background-color: #0000003b;
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  visibility: hidden;
  transition: margin-right .5s;
  overflow-y:scroll;
  overflow-x:hidden;
  margin-right: 0px;
}

.products-list-header-title {
  font-size: 1.786em;
  line-height: 1.6em;
}

.products-side-menu-category-item {
    color: #333e48;
    padding: 0.571em 0 0.643em 0;
    display: flex;
    align-items: center;
}

/* (A) FIXED WRAPPER */
.hwrap {
  overflow: hidden; /* HIDE SCROLL BAR */
  background: #eee;
  height: 60px;
  display: flex;
  align-items: center;
}
 
/* (B) MOVING TICKER WRAPPER */
.hmove { display: flex; align-items: center;}

/* (C) ITEMS - INTO A LONG HORIZONTAL ROW */
.hitem {
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
  font-size: 20px;
  display: flex;
}
 
/* (D) ANIMATION - MOVE ITEMS FROM RIGHT TO LEFT */
/* 4 ITEMS -400%, CHANGE THIS IF YOU ADD/REMOVE ITEMS */
@keyframes tickerh {
  0% { transform: translate3d(100%, 0, 0); }
  100% { transform: translate3d(-500%, 0, 0); }
}
.hmove { animation: tickerh linear 50s infinite; }
/* .hmove:hover { animation-play-state: paused; } */

/* Ads Arabic */
.hmove-ar { animation: tickerh linear 50s infinite; }
/* .hmove:hover { animation-play-state: paused; } */

.hwrap-ar {
  overflow: hidden; /* HIDE SCROLL BAR */
  background: #eee;
  height: 60px;
  display: flex;
  align-items: center;
}
 
/* (B) MOVING TICKER WRAPPER */
.hmove-ar { display: flex; align-items: center;}

/* (C) ITEMS - INTO A LONG HORIZONTAL ROW */
.hitem-ar {
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
  font-size: 20px;
  display: flex;
}
 
/* (D) ANIMATION - MOVE ITEMS FROM RIGHT TO LEFT */
/* 4 ITEMS -400%, CHANGE THIS IF YOU ADD/REMOVE ITEMS */
@keyframes tickerh-ar {
  0% { transform: translate3d(-300%, 0, 0); }
  100% { transform: translate3d(100%, 0, 0); }
}
.hmove-ar { animation: tickerh-ar linear 30s infinite; }

/* Flash sells list */
.flashsells > ul {
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  margin: 0 0;
  overflow-x: scroll;
  overflow-y: none;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
}
.flashsells > ul::-webkit-scrollbar {
  display: none;
}
.flashsells > ul > li {
  padding: 0 1rem 0 0;
  scroll-snap-align: start; /* or: center */
  scroll-snap-stop: always;
}
.flashsells > ul > li > div {
  background-color: #ffffff;
  border-radius: 9px;
  width: 280px;
  height: 280px;
}

.brands-wraper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  list-style: none;
  align-items: center;
  white-space: nowrap;
}
.brands > ul {
  white-space: nowrap;
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  margin: 0 0;
  overflow-x: scroll;
  overflow-y: none;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
}
.brands > ul::-webkit-scrollbar {
  display: none;
}
.brands > ul > li {
  padding: 0 1rem 0 0;
  width: 70px;
  height: 70px;
  scroll-snap-align: start; /* or: center */
  scroll-snap-stop: always;
}
.brands > ul > li > div {
  background-color: #ffffff;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  border: 4px solid #D9D9D9;
  cursor: pointer;
  transition: border 0.05s;
}

.brands > ul > li > div:hover {
  border: 4px solid #ED1C24;
  transition: border 0.2s;
}

.brands > ul > li > div > img {
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  object-fit: cover;
  transition: border 0.05s;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.column-same-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.column-same-height > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

/* BEST SELLERS */
.best-sellers > ul {
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  margin: 0 0;
  overflow-x: scroll;
  overflow-y: none;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
}
.best-sellers > ul::-webkit-scrollbar {
  display: none;
}
.best-sellers > ul > li {
  padding: 0 2rem 0 0;
  scroll-margin-left: 25px;
  scroll-margin-right: 25px;
  scroll-snap-align: start; /* or: center */
  scroll-snap-stop: always;
}

.best-sellers > ul > li > div {
  border-radius: 9px;
  width: 260px;
}


/* YOU MAY ALSO LIKE */
.you-may-also-like > ul {
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  overflow-x: scroll;
  overflow-y: none;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
}
.you-may-also-like > ul::-webkit-scrollbar {
  display: none;
}
.you-may-also-like > ul > li {
  padding: 0 2rem 0 0;
  scroll-snap-align: start; /* or: center */
  scroll-snap-stop: always;
}
.you-may-also-like > ul > li > div {
  border-radius: 9px;
  width: 160px;
}

.item-shadow {
  /* box-shadow: none;
  transition: transform ease 0.5s, box-shadow ease 0.5s; */
}

.item-shadow:hover {
  /* transform: translateY(-0px);
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.055); */
}

.product-search-item {
  background-color: white;
}

.product-search-item:hover {
  background-color: red;
}

.red {
  color: red;
}

.no-bullets {
  list-style-type: none;
}

.underline {
  text-decoration: underline;
}



.checkbox-unchecked {
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
  background: #D9D9D9;
  content: "";
}

.checkbox-unchecked:hover {
  background: #f6a1a5;
}
.checkbox-checked:hover {
  background: #F8B2B6;
}
.checkbox-checked {
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
  background: #F8B2B6;
}

/* .checkbox[type="checkbox"]:checked:after {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAQAAABuW59YAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAB2SURBVHjaAGkAlv8A3QDyAP0A/QD+Dam3W+kCAAD8APYAAgTVZaZCGwwA5wr0AvcA+Dh+7UX/x24AqK3Wg/8nt6w4/5q71wAAVP9g/7rTXf9n/+9N+AAAtpJa/zf/S//DhP8H/wAA4gzWj2P4lsf0JP0A/wADAHB0Ngka6UmKAAAAAElFTkSuQmCC'), linear-gradient(135deg, #B1B6BE 0%, #FFF 100%);
} */

.checkbox[type="checkbox"]:disabled:after {
  -webkit-filter: opacity(0.4);
}

.disable-selection {
  user-select: none;
  -webkit-user-select: none; /*Safari*/
  -moz-user-select: none; /*Firefox*/
}

.text-container {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Adjust the number of lines as needed */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3.6em;
  font-size: 16px;
  line-height: 1.2em;
}

.product-image {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.product-image > * {
  flex: 1;
  width: 100%;
  height: 100%;
}
