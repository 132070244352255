content {
    display: flex;
    justify-content: space-between;
    border: 1px solid #dddddd;
    padding: .464em 3em;
}

menuItem {
    color: #334141;
    font-size: 12;
}

separator {
    font-size: 12;
    color: #cad5d5;
    margin: 0 1em;
    /* pad: 0 1em   */
}

search-input {
    font: 1.25rem/3 sans-serif;
    color: #9F9F9F;
}

search-input::placeholder {
    font: 1.25rem/3 sans-serif;
    color: #9F9F9F;
}